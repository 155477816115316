//
// helper.scss
//

.section {
    padding: 60px 0;
    position: relative;
}
.section.homepage {
    padding: 72px 0;
}

.section-two {
    padding: 60px 0;
    position: relative;
}

.bg-overlay {
    background-color: $overlay;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 100%
}
.bg-gradident-overlay {
    background-color: $gradident-overlay;
}
.bg-overlay-white {
    background-color: $bg-overlay-white;
}
.bg-overlay-dark {
    background-color: $bg-overlay-dark;
}

.bg-gradient-primary {
    background: $bg-gradient-primary;
}

//Title
.title-heading {
    line-height: 26px;
    .heading {
        font-size: 45px !important;
        letter-spacing: 1px;
    }
    .para-desc {
        font-size: 18px;
    }
}

.section-title {
    position: relative;
    .title {
        letter-spacing: 0.5px;
        font-size: 26px !important;
    }
}

.para-desc {
    max-width: 600px;
}

.mt-100 {
    margin-top: 100px;
}

.mb-60 {
    margin-bottom: 60px;
}

//Shapes
.shape {
    position: absolute;
    pointer-events: none;
    right: 0;
    bottom: -1px;
    left: 0;
    &>svg {
        transform: scale(2);
        width: 100%;
        height: auto;
        transform-origin: top center;
    }
}

@media (min-width: 768px) {
    .margin-top-100 {
        margin-top: 100px;
        .heading {
            font-size: 40px;
        }
    }
}

@media (max-width: 768px) {
    .section {
        padding: 60px 0;
    }
    .title-heading {
        .heading {
            font-size: 35px !important;
        }
        .para-desc {
            font-size: 18px;
        }
    }
    .section-title {
        .title {
            font-size: 24px;
        }
    }
    .mt-60 {
        margin-top: 60px;
    }
}

@media (max-width: 767px) {
    .shape {
        bottom: -1px;
    }
}


@keyframes move {
  100% {
    transform: translate3d(0, 0, 1px) rotate(360deg);
  }
}
.process{
    .icon {
        width: 60px;
        height: 60px;
        line-height: 45px;
        transition: all 0.5s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        &.icon-uil {
            display: flex;
            justify-content: center;
            align-items: center;
            line-height: inherit;
        }
    }
    &.process-bar {
        &:after {
            display: block;
            width: 50%;
            position: absolute;
            content: ' ';
            border-width: 0 0 1px 0;
            border: 1px $gray-300 dashed;
            top: 20%;
            left: 75%;
            @media (max-width: 767px) {
                display: none;
            }
        }
    }
}
.carousel .thumbs-wrapper{
    margin: 0 !important;
}