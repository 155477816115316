//
// contact.scss
//

.form-icon {
    .icons {
        position: absolute;
        top: 13px;
        left: 18px;
    } 
}

.custom-form {
    &.contact-creative {
        position: absolute;
        bottom: 50%;
        transform: translateY(50%);
        right: 15px;
        left: 15px;
        margin: 0 auto;
        max-width: 445px;
        display: none;
        box-shadow: 0 10px 25px rgba($dark, 0.15);
        .contact-hide-btn {
            color: $dark;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            width: 30px;
            height: 30px;
            background-color: $white;
            border: 1px solid $gray-300;
            border-radius: 50%;
            top: -15px;
            right: -15px;
            transition: all 0.4s;
            &:hover {
                background-color: $dark;
                color: $white;
            }
        }
    }
}
.contact-show-btn {
    position: fixed;
    width: 100px;
    height: 100px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        z-index: -2;
        width: 100%;
        height: 100%;
        background-color: $primary;
        border-radius: 50%;
        box-shadow: 0 10px 25px rgba($primary, 0.5);
        animation: beatbtn 0.9s ease-in-out infinite;
    }
}
  
@keyframes beatbtn {
    0% {
        background-color: rgba($primary, 1);
        transform-origin: center;
        transform: scale(1);
    }

    50% {
        background-color: rgba($primary, 0.8);
        transform-origin: center;
        transform: scale(1.1);
    }
}

//Valid Contact form
.error {
    margin: 8px 0px;
    display: none;
    color: $danger;
}
  
#ajaxsuccess {
    font-size: 16px;
    width: 100%;
    display: none;
    clear: both;
    margin: 8px 0px;
}

.error_message,
#success_page {
    padding: 10px;
    margin-bottom: 20px;
    text-align: center;
    border-radius: 5px;
}

.error_message {
    background-color: rgba(240, 115, 90, 0.1) !important;
    border: 2px solid rgba(240, 115, 90, 0.1) !important;
    color: #f0735a !important;
    font-size: 14px;
}

.contact-loader {
    display: none;
}
  
#success_page {
    background-color: rgba(83, 199, 151, 0.1) !important;
    border: 2px solid rgba(83, 199, 151, 0.1) !important;
    color: #53c797 !important;
    p {
        margin-bottom: 0 !important;
    }
}