/* src/assets/css/PersistentPlayer.css */
body {
  padding-bottom: 100px; /* Height of PersistentPlayer */
}
.persistent-player {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height:100px;
  z-index: 1050; /* Ensure it’s above other elements */
  background-color: #343a40; /* Dark background for contrast */
  color: white;
}

/* src/components/SongForm.css or your main stylesheet */
@media (max-width: 767.98px) { /* Target screens smaller than 'md' (768px) */
  .song-form-title {
    font-size: 2rem !important; /* Adjust the font size as needed */
  }
}
