//
// footer.scss
//
@use "sass:color";

.footer {
    position: relative;
    padding: 60px 0;
    background-color: $footer;
    color: $gray-500;
    &.footer-bar {
        // border-top: 1px solid lighten($gray-700, 7%);
        border-top: 1px solid color.adjust($gray-700, $lightness: 7%);

        padding: 30px 0;
    }
    .logo-footer {
        font-size: 24px;
        padding: 3px 0;
        letter-spacing: 1px;
        font-weight: 600;
    }
    .footer-head {
        font-size: 18px;
        letter-spacing: 0.8px;
    }
    .text-foot {
        color: $gray-500;
    }
    .footer-list {
        margin-bottom: 0;
        li {
            margin-bottom: 10px;
            a{
                transition: all 0.5s ease;
                &:hover {
                    color: $primary !important;
                }
            }
            &:last-child{
                margin-bottom: 0;
            }
        }
    }
}