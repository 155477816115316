//
// feature.scss
//
.feature {
    &.feature-transition {
        transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
    }
    &.serve-one {
        .icons {
            height: 42px;
            width: 42px;
            line-height: 42px;
        }
        .content {
            .title {
                transition: all 0.5s ease;
            }
        }
    }
    .icon {
        height: 62px;
        width: 62px;
        line-height: 62px;
        .feature-icon {
            stroke-width: 1.5;
        }
    }
    &:hover {
        &.feature-transition {
            box-shadow: $shadow;
            transform: translateY(-5px);
        }
        .icon {
            background-color: $primary;
            .feature-icon {
                color: $white !important;
            }
        }
    }
}

.feature .icon,
.feature.serve-one .icons {
    display: inline-block;
    transition: all 0.4s ease;
    overflow: hidden;
    position: relative;
}

.space-feature {
    transition: all 0.5s ease;
    &:hover {
        background-color: $light;
        box-shadow: $shadow-lg !important;
    }
}

//Travel Landing
.feature-transform {
    transition: all 0.5s ease;
    margin: -200px 0 0px;
    z-index: 2;
    .icon {
        height: 80px;
        width: 80px;
        line-height: 80px;
    }
    .title {
        &:hover {
            color: $primary !important;
        }
    }
    &:hover {
        transform: translateY(-10px);
        box-shadow: $shadow-lg !important;
    }
}

@media screen and (max-width: 767px) {
    .feature-transform {
        margin: 0 0 30px;
    }
}



.img-icon{
    width: 45px;
    height: 45px;
}

.saas-icon{
    font-size: 31px;
}

.timeline-area{
    border-left: 2px solid $gray-200;
    margin: 18px 4px;
    padding: 0 20px 0 30px;
}
.timeline-inner{
    background-color: $light;
    padding: 18px 20px;
    border-radius: 15px;
    position: relative;
    margin-bottom: 20px;
    &::before{
        content: '';
        display: block;
        position: absolute;
        width: 10px;
        border-radius: 50%;
        background-color: $white;
        border: 3px solid $primary;
        padding: 2px;
        top: 27px;
        left: -36px;
    }
}

.feature-app{
    transition: all .5s;
    border-radius: 5px;
    
    &:hover{
        border-radius: 40px 10px 40px 10px;
        box-shadow: $shadow-md;
    }
}