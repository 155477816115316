//
// team.scss
//

.team, 
.job-candidate {
    transition: all 0.5s cubic-bezier(0.68, -0.55, 0.27, 1.55) 0s;
    img {
        transition: all 0.5s ease;
    }
    .team-social, 
    .candidate-position {
        position: absolute;
        bottom: 0;
        right: 0;
        left: 0;
        opacity: 0;
        transition: all 0.4s ease;
    }
    &:hover {
        box-shadow: $shadow;
        transform: translateY(-5px);
        img {
            transform: scale(1.1);
        }
        .team-social, 
        .candidate-position {
            opacity: 1;
        }
    }
}