//
// bootstrap-custom.scss
//
@use "sass:color";

.container {
    @media (min-width: 1400px) {
        max-width: 1140px !important;
    }
}

.row > * {
    position: relative;
}

@each $heading,
$size in $heading-font-sizes {
    #{$heading} {
        font-size: $size !important;
    }
}

@each $heading,
$size in $display-font-sizes {
    .#{$heading} {
        font-size: $size !important;
    }
}

@each $name,
$value in $colors {
    .bg-#{$name} {
        background-color: #{$value} !important;
    }
    .text-#{$name} {
        color: #{$value} !important;
    }
    a {
        &.text-#{$name} {
            &:hover,
            &:focus {
                color: color.adjust($value, $lightness: -10%) !important;
            }
        }
    }
}

//li (list inline item)
.list-inline-item:not(:last-child) {
    margin-right: 0px;
    margin-bottom: 5px;
}

// Rounded
.rounded {
    border-radius: 5px !important;
}
.rounded-top {
    border-top-left-radius: 5px !important;
    border-top-right-radius: 5px !important;
}
.rounded-left {
    border-top-left-radius: 5px !important;
    border-bottom-left-radius: 5px !important;
}
.rounded-bottom {
    border-bottom-left-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}
.rounded-right {
    border-top-right-radius: 5px !important;
    border-bottom-right-radius: 5px !important;
}

.rounded-md {
    border-radius: 10px !important;
}
.rounded-lg {
    border-radius: 30px !important;
}

//Border
.border {
    border: 1px solid $gray-200 !important;
}
.border-top {
    border-top: 1px solid $gray-200 !important;
}
.border-bottom {
    border-bottom: 1px solid $gray-200 !important;
}
.border-left {
    border-left: 1px solid $gray-200 !important;
}
.border-right {
    border-right: 1px solid $gray-200 !important;
}

//small
.small, small {
    font-size: 85%;
    font-weight: 500;
}

//Display
@media (max-width: 768px) {
    .display-1 {
        font-size: 56px !important;
    }
    .display-2 {
        font-size: 50px !important;
    }
    .display-3 {
        font-size: 42px !important;
    }
    .display-4 {
        font-size: 36px !important;
    }
}