//
// testi.scss
//

.review {
    .content {
        .icons { 
            position: absolute;
            top: 4%;
            right: 4%;
            opacity: 0.07;
        }
    }
    .review-icon {
        position: absolute;
        top: -20px;
        right: 0;
        opacity: 0.2;
    }
}

.tns-nav {
    text-align: center;
    margin-top: 10px;
    button {
        width: 6px;
        height: 6px;
        border-radius: 3px;
        background: rgba($primary, 0.4) !important;
        transition: all 0.5s ease;
        opacity: 1;
        margin: 4px;
        border: none;
        &.tns-nav-active {
            background: $primary !important;
            width: 10px;
        }
    }
}

.testi-hero {
    .content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 10%;
        left: 10%;
    }
}