//
// variables.scss
//
@use "sass:color";


// Color
$white:                     #ffffff;

$primary:                   #00c9a7; //335ff0
$secondary:                 #5a6d90;
$success:                   #25b865; //00F2A9
$warning:                   #f9a836;
$info:                      #44bed0;
$danger:                    #d6374a;
$dark:                      #3c4858;
$black:                     #161c2d;
$muted:                     #8492a6;
$light:                     #f7f7ff; //f8f9fc
$blue:                      #2d84fb;

// Gray
$gray-100:                  #f8f9fa;
$gray-200:                  #e9ecef;
$gray-300:                  #dee2e6;
$gray-400:                  #ced4da;
$gray-500:                  #adb5bd;
$gray-600:                  #6c757d;
$gray-700:                  #495057;
$gray-800:                  #121921;

//Custom
// $footer:                 lighten($black, 10%);
$footer:                    color.adjust($black, $lightness: 10%);

// Overlay
$overlay:                   rgba($dark, 0.7);
$gradident-overlay:         rgba($primary, 0.6);
$bg-overlay-white:          rgba($white, 0.7);
$bg-overlay-dark:           rgba($black, 0.75);
$bg-gradient-primary:       linear-gradient(to right, color.adjust($primary, $lightness: -10%) 0%, color.adjust(transparent, $lightness: -20%) 100%);

// Body Background
$body-color:                $black;
$shadow:                    0 0 3px rgba($dark,.15);
$shadow-md:                 0 5px 13px rgba($dark, .2);
$shadow-lg:                 0 10px 25px rgba($dark, 0.15);

//Color switcher
$default:                   #00c9a7;
$red:                       #D52941;
$skyblue:                   #32c2ec;
$blue:                      #2d84fb;
$yellow:                    #f89d36;
$green:                     #6dc77a;

// Base font
$font-size-base:            16px;

$font-family-base:          'Muli', sans-serif;
$font-family-secondary:     'Muli', sans-serif;

$colors: (
    "primary": $primary,
    "secondary": $secondary,
    "success": $success,
    "warning": $warning,
    "info": $info,
    "danger": $danger,
    "dark": $dark,
    "muted": $muted,
    "white": $white,
    "light": $light,
    "red": $red,
    "skyblue": $skyblue,
    "blue": $blue,
    "yellow": $yellow,
    "green": $green,
    "footer": $footer,
);

$heading-font-sizes: (
    "h1": 42px,
    "h2": 36px,
    "h3": 30px,
    "h4": 24px,
    "h5": 20px,
    "h6": 16px,
);

$display-font-sizes: (
    "display-1": 80px,
    "display-2": 72px,
    "display-3": 64px,
    "display-4": 56px,
)