//
// general.scss
//
@use "sass:color";

body {
    font-family: $font-family-secondary;
    overflow-x: hidden !important;
    font-size: $font-size-base;
    color: $dark;
}
h1, h2, h3, h4, h5, h6, .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $font-family-base;
    line-height: 1.5;
    font-weight: 600 !important;
}
::selection {
    background: color.adjust($dark, $lightness: 5%);
    color: $white;
}
a {
    text-decoration: none !important;
    cursor: pointer;
}
p {
    line-height: 1.6;
}
.m-x-2{
    margin: 0 3px;
}
.react-parallax-bgimage{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover !important;
}